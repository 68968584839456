@import "sass-lib";

@media (min-width: 0px) and (max-width: 740px) {
	h3{font-size: 23px;line-height: 30px;}
	h5{font-size: 16px;}
	header{
		padding: 0 20px;height: 60px;
		.logo{
			padding-top: 15px;
			img{width: 110px;}
		}
		.nav-head{
			top: 60px;width: 100%;
			&:before,&:after{display: none;}
		}
		.nav-right{
			margin-top: 18px;
			.lang-head,.search-head{display: none;}
			.burg-menu{
				width: 35px;
				span{
					&:nth-child(3){width: 100%;}
				}
			}
		}
	}
	.row_list{
        width: auto;display: block;
        .col{
            &.col_xsml{width: 100%;}
            &.col_xsml8{width: 80%;}
            &.col_xsml75{width: 75%;}
            &.col_xsml7{width: 70%;}
            &.col_xsml65{width: 65%;}
            &.col_xsml6{width: 60%;}
            &.col_xsml55{width: 55%;}
            &.col_xsml5{width: 50%;}
            &.col_xsml45{width: 45%;}
            &.col_xsml4{width: 40%;}
            &.col_xsml37{width: 37%;}
            &.col_xsml35{width: 35%;}
            &.col_xsml33{width: 33.3333333333333%;}
            &.col_xsml3{width: 30%;}
            &.col_xsml25{width: 25%;}
            &.col_xsml2{width: 20%;}
            &.col_xsml16{width: 16.66666666666666%;}
            &.col_xsml1{width: 10%;}

            &.nomargmb{margin-bottom: 0;}
        }
        &.center{
            text-align: center;
            .col{float: none; display: inline-block;}
        }
        &.listfull{
            margin-left: 0; margin-right: 0;
            .col{padding: 0;}
        }
        &.listp5{
            margin-left: 0; margin-right: 0;
            .col{padding: 0;}
        }
    }
	.pop{
		overflow: auto;
		.inpopup{
			width: 90%;
			.close-pop{
				width: 30px;height: 30px;
				span{width: 20px;height: 2px;}
			}
			.text{
				p,a{font-size: 14px;line-height: 20px;}
			}
		}
		&#popHome{
			.inpopup{
				height: 269px;
				.close-pop{left: auto;right: 0;}
				.box-pop{
					img{vertical-align: middle;height: 265px;}
				}
			}
		}
	}
	.box-sliderhome{
		.arr-slide{display: none;}
		.ket{
			bottom: 20px;width: 55%;line-height: 18px;
			&:before{bottom:auto;margin: 0;top: 7px;}
		}
	}
	.pg-down{
		bottom: 0;left: auto;margin: 0;height: 54px;
	}
	.sliderhome{
		.list{
			figure{
				height: 420px;
				img{width: 100%;height: 420px;object-fit: cover;}
			}
			.play-vid{width: 80px;height: 60px;background-size: 100%;}
			.centerimg{margin-top: 0;width: 80%;}
			.text-banner{
				width: 90%;
				h2{font-size: 28px;line-height: 34px;margin-bottom: 10px;}
				p{font-size: 14px;line-height: 20px;}
			}
		}
		.slick-dots{
			position: absolute;bottom: 75px;left: 50%;@include transform(translateX(-50%));
			li{
				float: left;margin: 0 3px;
				button{width: 10px;height: 10px;@include boxSizing(border-box);padding: 0;font-size: 0;color: transparent;@include borderRadius(100%);border: 1px solid $white;background: transparent;}
				&.slick-active{
					button{background: $white;}
				}
			}
		}
	}
	.content{margin-top: 60px;}
	.home{
		h2{font-size: 20px;line-height: 25px;}
		.tittle{margin-bottom: 20px;}
		.wrapper{padding: 0 20px;}
		&.qts{
			padding: 30px 0;
			h2{font-size: 20px;line-height: 25px;}
			p{font-size: 16px;line-height: 24px;}
			&:before{ width: 100%; height: auto;  background-size: 100%; }
		}
		&.solution{
			.box-solution{
				margin: 0;
				h4{margin-bottom: 10px;}
				.list-sol{
					width: 100%;margin-bottom: 20px;padding: 0 0 20px;
				}
			}
		}
		&.newsupdate{
			.wrap-newsupdate{
				.box-highlt{
					margin-bottom: 30px;
					figure{position: relative;top: auto;left: auto;bottom: auto;width: 100%;}
					.txt{width: 100%;padding: 15px;}
				}
				.list-small{
					width: 100%;
					.desc{
						h5{height: auto;max-height: 60px;}
						p{max-height: 54px;height: auto;overflow: hidden;}
					}
				}
			}
		}
	}
	.fixbtn{display: none;}
	.sc-padd{padding: 25px 0;}
	.byside{
		h2{font-size: 23px;line-height: 30px;}
		.capt-small{font-size: 14px;line-height: 20px;margin-bottom: 5px;}
	}
	.tx-Reliance{
		p{font-size: 16px;line-height: 20px;}
	}
	.box-video{
		iframe,.yvideo{height: 230px;}
	}
	.box-partner{
		.list{
			width: 100%;margin-right: 0;margin-bottom: 20px;
			figure{
				text-align: center;
				img{width: 100%;}
			}
		}
	}
	.box-name{
		.text{padding: 10px 15px;}
		.list-member{width: 100%;margin-right: 0;}
	}
	.popmiddle{
		.inpopup{
			padding: 45px 15px;
			.close-pop{width: 45px;height: 45px;}
			.box-pop{overflow: auto;max-height: 65vh;}
			.left-content, .text{width: 100%;}
			.text{
				max-height: 300px;
				h4{font-size: 19px;}
			}
		}
	}
	.box-value{
		figure{
			width: 100%;
			img{width: 100%;}
		}
		.txt{
			width: 100%;padding: 15px 0 0;
			h3{font-size: 23px;line-height: 30px;margin-bottom: 5px;}
		}
	}
	.box-financial{
		margin-top: 40px;
		figure,.txt{width: 100%;}
		figure{
			margin-bottom: 20px;text-align: center;
		}
		.txt{
			h5{font-size: 16px;}
			p{font-size: 14px;line-height: 20px;}
		}
		.btn.readmore{padding: 0 20px;min-width: 150px;text-align: center;}
	}
	.box-career{
		.row{
			margin-bottom: 0;
			.col{width: 100%;margin-right: 0;margin-bottom: 20px;}
		}
	}
	.box-form{
		.g-recaptcha{@include transform(scale(.8));transform-origin: 0 0;}
		&.form-contact{
			label{font-size: 14px;}
		}
	}
	.box-acc{
		.list-acc{
			.btn-acc{
				padding: 15px 40px 15px 15px;
				h6{margin-bottom: 8px;}
				p{line-height: 18px;}
				.plus{right: 10px;}
			}
			.ctn-acc{
				padding: 15px;
				h5{font-size: 16px;}
				ul li, ol li,p{font-size: 14px;line-height: 20px;}
			}
		}
	}
	.wrap-news{
		.list-news{width: 100%;}
	}
	.pagination{
		a{
			font-size: 13px;margin: 0 10px;
			&.prev, &.next{font-size: 0;color: transparent;padding: 0 15px;}
		}
	}
	.contact-side{
		padding-bottom: 30px;
		.left,.right{width: 100%;}
		.left{margin-bottom: 40px;}
		.box{
			h4{font-size: 16px;margin-bottom: 5px;}
			p{font-size: 14px;line-height: 20px;}
		}
		&.bg-wh{
			.right{width: 100%;padding: 0;}
		}
	}
	.map{height: 300px;}
	.box-milestone{
		margin: 40px 0 20px;
		&:before{display:none;}
		.dots{display: none;}
		.ls-milestone{
			&:before{display:none;}
			h3{padding: 0 30px;}
			p{font-size: 14px;line-height: 20px;}
			.in-milestone{width: 100%;padding: 0;text-align: center;}

			&.slick-current,&.active{
				h3{font-size: 23px;}
			}
			&:nth-child(even){
				.in-milestone{padding: 0;text-align: center;}
			}
		}
		.slick-arrow{
			left: 0;margin-left: 0;
			&.slick-prev{@include transform(rotate(-90deg));top: 8px;}
			&.slick-next{@include transform(rotate(-90deg));bottom: auto;top: 8px;left: auto;right: 0;}
		}
	}
	.listdata{
		.list{padding: 20px 0;}
	}
	.box-sorting{
		a{font-size: 16px;padding: 0 15px;}
		.left,.right{width: 100%;}
		.left{margin-bottom: 20px;}
	}
	.box-news{
		.ls-news{
			width: 100%;margin-bottom: 20px;
			.desc{
				border-bottom: 1px solid #e2e2e2;
				span{font-size: 14px;}
				h6{line-height: 26px;max-height: 52px;}
				p{font-size: 14px;line-height: 20px;}
			}
		}
	}
	.box-detail-finan{
		margin-top: 40px;
		figure{margin-bottom: 20px;}
		.txt{
			h4{font-size: 20px;margin-bottom: 10px;}
			p{font-size: 14px;line-height: 20px;margin-bottom: 15px;}
		}
		.list{padding-bottom: 30px;margin-bottom: 30px;}
	}
	.headerror{
		padding: 15px 0;
		img{width: 100px;}
	}
	.contenterror{
		height: auto;margin-top: 82px;
		.mid{
			width: 100%;position: relative;top: auto;left: auto;right: auto;bottom: auto;@include transform(translate(0,0));padding: 0 20px;@include boxSizing(border-box);
			h4{font-size: 20px;line-height: 26px;margin-bottom: 30px;}
			h5{font-size: 14px;line-height: 20px;margin-bottom: 30px;}
			figure{
				margin-bottom: 30px;
				img{max-width: 80%;max-height: 120px;}
			}
		}
	}


	footer{
		padding: 20px 20px 15px;margin-top: 40px;
		.wrapper{padding: 0;}
		.left,.right{width: 100%;}
		.left{
			margin-bottom: 20px;
			.logo,.box-addrs{width: 100%;margin-right: 0;text-align: center;}
			.logo{
				margin-bottom: 20px;
				img{width: 100px;}
			}
			.box-addrs{
				p{line-height: 18px;}
				.tittle-fot{margin-bottom: 5px;}
			}
		}
		.right{
			.row{
				display: flex;flex-direction: column;
				.col{
					width: 100%;margin-right: 0;text-align: center;margin-bottom: 15px;
					&:first-child{order:2;margin-bottom: 0;}
					&:nth-child(2){order:1;}
				}
			}
			.cpy{
				text-align: center;
				p{line-height: 18px;}
			}
		}
	}
}